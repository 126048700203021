<template>
  <a-card :bordered="false" class="card-area">

    <common-table
      ref="table"
      path="vendor"
      :columns="columns">

      <template slot="search">
        <a-button ghost type="primary" @click="add">新增</a-button>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-view @click="vendorDevices(record)"></action-view>
        <action-edit @click="edit(record)"></action-edit>
        <action-delete @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <vendor-edit ref="vendorEdit" @success="getList" ></vendor-edit>

  </a-card>
</template>
<script>
import VendorEdit from './VendorEdit'

export default {
  components: { VendorEdit },
  data () {
    return {

    }
  },
  created () {

  },
  computed: {
    columns () {
      return [
        {
          title: 'ID',
          dataIndex: 'id'
        },
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '登录账号',
          dataIndex: 'account'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        // {
        //   title: '设备数',
        //   dataIndex: 'deviceCount'
        // },
        {
          title: '添加时间',
          dataIndex: 'createdAt'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        }
      ]
    }
  },
  methods: {
    add () {
      this.$refs.vendorEdit.show()
    },
    edit(record){
      this.$refs.vendorEdit.show(record)
    },
    remove(record){
      let that = this
      this.$confirm({
        title: '是否删除该经销商?',
        content: '该操作不可撤销',
        centered: true,
        onOk () {
          that.$delete('vendor/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },

    vendorDevices(record){
      let vendorId = record.id
      this.$router.push(`/vendor/${vendorId}/product`)
    },

    getList () {
      this.$refs.table.getData()
    }

  }
}
</script>
<style lang="less" scoped>

</style>
