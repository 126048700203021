<template>
  <drawer-form
    ref="drawerForm"
    :title="model.id>0 ? '编辑':'新增'"
    :model="model"
    :rules="rules"
    :loading="loading"
    @handleSubmit="handleSubmit">

    <a-form-model-item label='名称' prop="name">
      <a-input v-model="model.name"/>
    </a-form-model-item>

    <a-form-model-item label='账号' prop="account">
      <a-input v-model="model.account" :disabled="model.id>0"/>
    </a-form-model-item>

    <a-form-model-item label='备注' prop="remark">
      <a-input v-model="model.remark"/>
    </a-form-model-item>

    <a-form-model-item label='权限选择'>
      <menu-tree v-model="selectedMenus" :treeData="menuTreeData" ></menu-tree>
    </a-form-model-item>

  </drawer-form>

</template>
<script>
  import MenuTree from '@/components/widget/MenuTree'

  export default {
    components:{MenuTree},
    data () {
      return {
        model:{
          name: '',
          account: '',
          remark: '',
        },
        loading: false,

        menuTreeData: [],
        selectedMenus: [],

        rules: {
          name: [
            {required: true, message: '必填项'},
            {max: 30, message: '长度不能超过30个字符'}
          ],
          account: [
            {required: true, message: '必填项'},
            {max: 50, message: '长度不能超过50个字符'}
          ],


        },

      }
    },
    methods: {
      show(model={}){
        this.model = JSON.parse(JSON.stringify(model))
        this.selectedMenus = []
        this.$refs.drawerForm.show()

        this.$get('menu').then((r) => {
          this.menuTreeData = r.data.rows.children

          if(this.model.id>0){
            this.$get('vendor/menu/' + this.model.id).then((r) => {
              this.selectedMenus = r.data
            })
          }

        })
      },
      hide () {
        this.$refs.drawerForm.hide()
      },
      handleSubmit () {
        this.model.menuId = this.selectedMenus.join(',')
        if(this.model.id>0){
          this.edit()
        }else{
          this.add()
        }
      },
      add(){
        this.loading = true
        this.$post('vendor', this.model).then((r) => {
          this.loading=false
          this.hide()
          this.$emit('success')
        }).catch(() => {
          this.loading = false
        })
      },
      edit(){
        this.loading = true
        this.$put('vendor', this.model).then((r) => {
          this.loading=false
          this.hide()
          this.$emit('success')
        }).catch(() => {
          this.loading = false
        })
      }
    },
  }
</script>
